// src/utils/transformData.js

/**
 * Transforms multiple fields into a comma-separated list of 'name' values for each specified field index.
 * @param {Array} data - The data array containing objects.
 * @param {Array} fieldIndexes - The list of field indexes that need to be transformed.
 * @returns {Array} - The transformed data with properly formatted fields.
 */
export const transformDataFieldsToNames = (data, fieldIndexes) => {
  return data.map((item) => {
      if (!item) return item;

      fieldIndexes.forEach((index) => {
          if (!item[index]) return; // Skip if field doesn't exist

          let fieldData = item[index];
          if (!fieldData) return; // If the field doesn't have data, skip the transformation

          if (typeof fieldData === 'string') {
              try {
                  // Parse the string into an array of objects
                  fieldData = JSON.parse(fieldData);

                  if (Array.isArray(fieldData)) {
                      // Transform into a string with 'name' values joined by commas
                      item[index] = fieldData.map(i => i.name).join(', ');
                  }
              } catch (e) {
                  // Gives parse error because of how it was stored as a string in the firstplace
              }
          }
      });
      return item;
  });
};
