import React, { useState } from 'react';
import axios from '../../../axios';
import { MdClose, MdOutlineEdit, MdOutlineEditOff } from 'react-icons/md';
import { getTranslation } from '../../../dictionary';
import { useLocaleStore } from '../../../store';

const RowDataModal = ({
  showModal,
  isEditing,
  selectedRowData,
  orderedHeaders,
  handleCloseModal,
  setIsEditing,
  setSelectedRowData,
  pageType,
  isAddIndicatorModal = false,
}) => {
  const [errors, setErrors] = useState({});
  const language = useLocaleStore((state) => state.language);

  const handleClearAndCloseModal = () => {
    setErrors({});
    handleCloseModal();
  };

  const handleSaveIndicator = async () => {
    const requiredFields = [
      { key: 'datasetName', value: 'Dataset name is verplicht' },
      { key: 'title', value: 'Title is verplicht' },
      { key: 'name_nl', value: 'Name (NL) is verplicht' },
      { key: 'name_en', value: 'Name (EN) is verplicht' },
      { key: 'description', value: 'Description (NL) is verplicht' },
      { key: 'description_en', value: 'Description (EN) is verplicht' },
      { key: 'indicator_category', value: 'Indicator category is verplicht' },
      { key: 'impact_areas_id', value: 'Impact area is verplicht' },
    ];

    // Validate required fields
    const newErrors = [];
    requiredFields.forEach((field) => {
      if (!selectedRowData[field.key]) {
        newErrors.push(field.value);
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Prevent submission if there are errors
    }

    if (isAddIndicatorModal) {
      try {
        const datasetName = selectedRowData.datasetName;
        if (!datasetName) {
          alert('Please specify a name for the dataset.');
          return;
        }

        const datasetResponse = await axios.post('/datasets', {
          data: {
            name: datasetName,
            year: null,
            amount: null,
          },
        });

        const newDatasetId = datasetResponse.data?.data?.id;
        if (!newDatasetId) {
          throw new Error('Failed to create a new dataset.');
        }

        const statisticResponse = await axios.post('/statistics', {
          data: {
            ...selectedRowData,
            dataset: newDatasetId,
          },
        });

        console.log('Indicator saved successfully:', statisticResponse.data);
        alert('Indicator saved successfully!');
        handleClearAndCloseModal();
        window.location.reload();
      } catch (error) {
        console.error('Error saving indicator:', error);
        alert('Failed to save the indicator. Please try again.');
      }
    } else {
      try {
        const statisticPutResponse = await axios.put(`/statistics/${selectedRowData.id}`, {
          data: {
            ...selectedRowData,
          },
        });
        console.log(statisticPutResponse);
        alert('Indicator updated successfully!');
        handleClearAndCloseModal();
        window.location.reload();
      } catch (error) {
        console.error('Error updating indicator:', error);
        alert('Failed to update the indicator. Please try again.');
      }

      console.log(modalHeaders);
    }
  };

  const fields = [
    'datasetName',
    'title',
    'name_nl',
    'name_en',
    'description',
    'description_en',
    'indicator_category',
    'indicator_category_nl',
    'indicator_category_en',
    'impact_areas_id',
    'impact_areas_nl',
    'impact_areas_en',
    'source',
    'responsible_entity',
    'geographic_area',
  ];

  const modalHeaders = isAddIndicatorModal || isEditing ? fields : orderedHeaders;

  const datasetNameOptions = [
    { value: '%', label: 'Percentage (%)' },
    { value: 'euro_month', label: 'Euro / maand' },
    { value: 'euro_person_year', label: 'Euro / persoon / jaar' },
    { value: 'hectare', label: 'Hectare' },
    { value: 'kg', label: 'Kilogram' },
    { value: 'number', label: 'Aantal' },
    { value: 'perc_total_area', label: 'Percentage totale oppervlakte (%)' },
    { value: 'ton_co2', label: 'Ton CO2' },
    { value: '°F', label: 'Graden Fahrenheit' },
  ];

  const indicatorCategoryOptions = [
    { value: 'economic_sustainability', label: 'Economisch' },
    { value: 'social_sustainability', label: 'Sociaal' },
    { value: 'urban_rural_integration', label: 'Relatie stad-platteland' },
    { value: 'environmental_sustainability', label: 'Milieu' },
    { value: 'food_governance', label: 'Beleid' },
  ];

  const categoryTranslations = {
    economic_sustainability: { nl: 'Economisch', en: 'Economic' },
    social_sustainability: { nl: 'Sociaal', en: 'Social' },
    urban_rural_integration: { nl: 'Relatie stad-platteland', en: 'Urban-Rural Integration' },
    environmental_sustainability: { nl: 'Milieu', en: 'Environmental' },
    food_governance: { nl: 'Beleid', en: 'Governance' },
  };

  const geographicOptions = [
    { value: 'arrondissement_leuven', label: 'Arrondissement Leuven' },
    { value: 'municipality_leuven', label: 'Municipality Leuven' },
    { value: 'flemish_brabant', label: 'Vlaams-Brabant' },
    { value: 'flanders', label: 'Vlaanderen' },
    { value: 'national', label: 'Nationaal' },
  ];

  const impactAreasOptions = {
    social_sustainability: [
      { value: 'accessibility_affordability', label: 'Toegankelijkheid en betaalbaarheid' },
      { value: 'health_welbeing_nutrition_utilisation', label: 'Gezondheid en welzijn' },
      { value: 'education_awareness', label: 'Educatie' },
      { value: 'sustainable_food_consumption', label: 'Duurzame voedselconsumptie' },
      { value: 'short_local_supply_chain', label: 'Korte keten' },
      { value: 'food_safety', label: 'Voedselveiligheid' },
      { value: 'social_inclusion', label: 'Sociale inclusie' },
    ],
    economic_sustainability: [
      { value: 'local_economy', label: 'Lokale economie' },
      { value: 'employment_opportunities', label: 'Werkgelegenheid' },
      { value: 'short_local_supply_chain', label: 'Korte keten' },
      { value: 'agricultural_sector', label: 'Landbouwsector in beeld' },
      { value: 'fair_trade', label: 'Eerlijke handel' },
    ],
    urban_rural_integration: [
      { value: 'production', label: 'Productie' },
      { value: 'mutual_benefits', label: 'Wederzijdse voordelen' },
      { value: 'short_local_supply_chain', label: 'Korte keten' },
    ],
    environmental_sustainability: [
      { value: 'ecosystem_management', label: 'Ecosysteembeheer' },
      { value: 'ghg_emissions', label: 'GHG emissies' },
      { value: 'food_loss_waste', label: 'Voedselverlies en -verspilling' },
    ],
    food_governance: [{ value: 'participation', label: 'Participatie' }],
  };

  const impactAreasTranslations = {
    accessibility_affordability: { nl: 'Toegankelijkheid en betaalbaarheid', en: 'Accessibility and Affordability' },
    health_welbeing_nutrition_utilisation: { nl: 'Gezondheid en welzijn', en: 'Health and Wellbeing' },
    education_awareness: { nl: 'Educatie', en: 'Education' },
    sustainable_food_consumption: { nl: 'Duurzame voedselconsumptie', en: 'Sustainable Food Consumption' },
    short_local_supply_chain: { nl: 'Korte keten', en: 'Short Local Supply Chain' },
    food_safety: { nl: 'Voedselveiligheid', en: 'Food Safety' },
    social_inclusion: { nl: 'Sociale inclusie', en: 'Social Inclusion' },
    local_economy: { nl: 'Lokale economie', en: 'Local Economy' },
    employment_opportunities: { nl: 'Werkgelegenheid', en: 'Employment Opportunities' },
    agricultural_sector: { nl: 'Landbouwsector in beeld', en: 'Agricultural Sector' },
    fair_trade: { nl: 'Eerlijke handel', en: 'Fair Trade' },
    production: { nl: 'Productie', en: 'Production' },
    mutual_benefits: { nl: 'Wederzijdse voordelen', en: 'Mutual Benefits' },
    ecosystem_management: { nl: 'Ecosysteembeheer', en: 'Ecosystem Management' },
    ghg_emissions: { nl: 'GHG emissies', en: 'GHG Emissions' },
    food_loss_waste: { nl: 'Voedselverlies en -verspilling', en: 'Food Loss and Waste' },
    participation: { nl: 'Participatie', en: 'Participation' },
  };

  const handleEditOrRedirect = () => {
    if (isAddIndicatorModal) {
      setIsEditing(true);
    } else {
      const redirectUrl = `/${pageType}/${selectedRowData.id || ''}`;
      window.location.href = redirectUrl;
    }
  };

  return (
    <div
      className={`modal fade ${showModal ? 'show' : ''}`}
      style={{ display: showModal ? 'block' : 'none' }}
      aria-hidden={!showModal}
      tabIndex={-1}
      role="dialog"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          {/* Modal Header */}
          <div className="modal-header d-flex justify-content-between align-items-center bg-purple text-white">
            <div className="d-flex align-items-center gap-3">
              <h5 className="modal-title mb-0">{isAddIndicatorModal ? 'Add Indicator' : 'Overzicht'}</h5>
              {!isAddIndicatorModal && (
                <p onClick={() => handleEditOrRedirect()} style={{ cursor: 'pointer' }} className="mb-0 text-light">
                  {isEditing ? <MdOutlineEditOff size={20} /> : <MdOutlineEdit size={20} />}
                </p>
              )}
            </div>
            <p className="close text-light" onClick={handleClearAndCloseModal} aria-label="Close">
              <MdClose size={20} />
            </p>
          </div>
 
          {/* Modal Body */}
          <div className="modal-body" style={{ height: 'calc(90vh - 120px)', overflowY: 'auto' }}>
            {/* Display error messages */}
            {Object.keys(errors).length > 0 && (
              <div className="alert alert-danger">
                <ul className="mb-0 list-unstyled">
                  {Object.values(errors).map((error, idx) => (
                    <li key={idx}>{error}</li>
                  ))}
                </ul>
              </div>
            )}

            {modalHeaders
              .filter((header) => {
                // Exclude these fields if isAddIndicatorModal is false and isEditing is true
                if (!isAddIndicatorModal && isEditing && header === 'datasetName') {
                  return false;
                }
                // Exclude the specific fields you had in the original filter logic
                return ![
                  'indicator_category_en',
                  'indicator_category_nl',
                  'impact_areas_en',
                  'impact_areas_nl',
                ].includes(header);
              })
              .map((header, idx) => (
                <div key={idx} className="mb-4">
                  <h6 className="font-weight-bold text-uppercase text-muted mb-2">
                    {getTranslation({ key: header, language })}
                  </h6>
                  {isEditing || isAddIndicatorModal ? (
                    header === 'datasetName' ? (
                      <select
                        id={header}
                        value={selectedRowData[header] || ''}
                        className="form-select mb-3"
                        onChange={(e) => setSelectedRowData({ ...selectedRowData, [header]: e.target.value })}
                      >
                        <option value="">Selecteer type dataset</option>
                        {datasetNameOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    ) : header === 'indicator_category' ? (
                      <select
                        id={header}
                        value={selectedRowData[header] || ''}
                        className="form-select mb-3"
                        onChange={(e) => {
                          const value = e.target.value;
                          const translations = categoryTranslations[value] || {};
                          setSelectedRowData({
                            ...selectedRowData,
                            [header]: value,
                            impact_areas_id: '', // Reset dependent field
                            indicator_category_nl: translations.nl || '',
                            indicator_category_en: translations.en || '',
                          });
                        }}
                      >
                        <option value="">Selecteer categorie</option>
                        {indicatorCategoryOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    ) : header === 'impact_areas_id' ? (
                      <select
                        id={header}
                        value={selectedRowData[header] || ''}
                        className="form-select mb-3"
                        onChange={(e) => {
                          const value = e.target.value;
                          const translations = impactAreasTranslations[value] || {};
                          setSelectedRowData({
                            ...selectedRowData,
                            [header]: value,
                            impact_areas_nl: translations.nl || '',
                            impact_areas_en: translations.en || '',
                          });
                        }}
                        disabled={!selectedRowData.indicator_category} // Disable if no category is selected
                      >
                        <option value="">Selecteer subcategorie</option>
                        {impactAreasOptions[selectedRowData.indicator_category]?.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        )) || (
                          <option value="" disabled>
                            No options available
                          </option>
                        )}
                      </select>
                    ) : header === 'geographic_area' ? (
                      <select
                        id={header}
                        value={selectedRowData[header] || ''}
                        className="form-select mb-3"
                        onChange={(e) =>
                          setSelectedRowData({
                            ...selectedRowData,
                            [header]: e.target.value,
                          })
                        }
                      >
                        <option value="">Selecteer geografisch gebied</option>
                        {geographicOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        id={header}
                        value={selectedRowData[header] || ''}
                        className={`form-control mb-3 ${errors[header] ? 'is-invalid' : ''}`}
                        onChange={(e) => setSelectedRowData({ ...selectedRowData, [header]: e.target.value })}
                      />
                    )
                  ) : (
                    <div className="form-control-plaintext text-wrap text-break mb-3">{selectedRowData[header]}</div>
                  )}
                  {idx < orderedHeaders.length - 1 && <hr className="my-2" />}
                </div>
              ))}
          </div>

          {/* Modal Footer */}
          <div className="modal-footer">
            <button type="button" className="button m rounded-3" onClick={handleClearAndCloseModal}>
              Cancel
            </button>
            {(isEditing || isAddIndicatorModal) && (
              <button type="button" className="button m rounded-3" onClick={handleSaveIndicator}>
                {isAddIndicatorModal ? 'Save Indicator' : 'Save Changes'}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RowDataModal;
