import { useQuery } from '@tanstack/react-query';
import React from 'react';
import ExpertCard from '../components/ExpertCard';
import InitiativeCard from '../components/InitiativeCard';
import Loading from '../components/Loading';
import StakeholderCard from '../components/StakeholderCard';
import { getTranslation } from '../dictionary';
import useStytchUserLoader from '../loaders/useStytchUserLoader';
import { fetchUserExperts } from '../services/ExpertService';
import { fetchUserInitiatives } from '../services/InitiativeService';
import { fetchUserStakeholders } from '../services/StakeholderService';
import { useLocaleStore } from '../store';
import FormCombo from './FormCombo';

function Userdata() {
  const language = useLocaleStore((state) => state.language);

  var { user } = useStytchUserLoader();

  const { isLoading: expertLoading, data: experts } = useQuery({
    queryKey: ['userExperts'],
    queryFn: () => fetchUserExperts(user?.user_id),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const { isLoading: initiativesLoading, data: initiatives } = useQuery({
    queryKey: ['userInitiatives'],
    queryFn: () => fetchUserInitiatives(user?.user_id),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const { isLoading: stakeholdersLoading, data: stakeholders } = useQuery({
    queryKey: ['userStakeholders'],
    queryFn: () => fetchUserStakeholders(user?.user_id),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  if (expertLoading || stakeholdersLoading || initiativesLoading) return <Loading />;

  if (user === null) {
    return (
      <>
        <div className="container-fluid pad-small-b m-4">
          <h2 className="mb-2 pb-1">
            <p className="m-4">You are not logged in</p>
          </h2>
        </div>
      </>
    );
  } else {
    return (
      <>
        <section className="indicators-intro pad-small">
          <div className="container-fluid">
            <div className="row pb-5">
              <div className="col-12">
                <div className="large-small-header-combo">
                  <h2>
                    {getTranslation({ key: 'your_information', language })}
                    <p className="font-serif-m pt-1">
                      {JSON.parse(sessionStorage.getItem('stytch-user'))?.emails[0].email}
                    </p>
                  </h2>
                </div>
                <p className="font-serif-l">{getTranslation({ key: 'thanks_for_logging_in', language })}</p>
              </div>
            </div>
          </div>
        </section>
        <section className="stakeholders-cards pad">
          <div id='userExperts' className="container-fluid pad-small-b">
            <h2 className="mb-1">
              {experts.length
                ? getTranslation({ key: 'your_experts', language })
                : getTranslation({ key: 'no_experts', language })}
            </h2>

            <div className="row" style={{ alignItems: 'flex-start' }}>
              {experts.map((d, i) => {
                return <ExpertCard d={d} key={`expert-${i}`} showClaimButton={true} />;
              })}
            </div>
          </div>
          <div id='userInitiatives' className="container-fluid pad-small-b">
            <h2 className="mb-1">
              {initiatives.length
                ? getTranslation({ key: 'your_initiatives', language })
                : getTranslation({ key: 'no_initiatives', language })}
            </h2>
            <div className="row" style={{ alignItems: 'flex-start' }}>
              {initiatives.map((d, i) => {
                return <InitiativeCard d={d} key={`initiative-${i}`} showClaimButton={true} />;
              })}
            </div>
          </div>
          <div id='userStakeholders' className="container-fluid pad-small-b">
            <h2 className="mb-1">
              <p>
                {stakeholders.length
                  ? getTranslation({ key: 'your_stakeholders', language })
                  : getTranslation({ key: 'no_stakeholders', language })}
              </p>
            </h2>

            <div className="row" style={{ alignItems: 'flex-start' }}>
              {stakeholders.map((v, i) => {
                return <StakeholderCard d={v} key={`stakeholder-${i}`} showClaimButton={true} />;
              })}
            </div>
          </div>
        </section>

        <FormCombo />
      </>
    );
  }
}
export default Userdata;
