import axios from "../../../axios";
import { useState, useEffect } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { useIsAdmin } from "../../../utils/adminChecker";
import { deleteAdmin } from "../../../services/adminServices/manageAdmins";

export const AdminsList = () => {
    const [admins, setAdmins] = useState([]);
    const { role } = useIsAdmin();

    useEffect(() => {
        const fetchAdmins = async () => {
            const result = await axios.get(`/admins`);
            const admins = result?.data?.data || [];
            admins.sort((a, b) => a.id - b.id);
            setAdmins(admins);
        };
        fetchAdmins();
    }, []);

    const handleDelete = async (adminId) => {
        try {
            if (!window.confirm("Are you sure you want to delete this admin?")) {
                return;
            }
            await deleteAdmin(adminId);
            setAdmins(admins.filter((admin) => admin.id !== adminId));
            window.location.reload();
        } catch (error) {
            console.error("Error deleting admin:", error);
            alert("Failed to delete the admin. Please try again.");
        }
    };

    return (
        <div className="container mt-5">
            <h3 className="mb-4 text-center">Admins List</h3>
            <div className="table-responsive d-flex justify-content-center">
                <table className="table table-bordered table-hover">
                    <thead className="table-light">
                        <tr>
                            <th scope="col" className="text-center">Email</th>
                            <th scope="col" className="text-center">Role</th>
                            <th scope="col" className="text-center">Created At</th>
                            <th scope="col" className="text-center">Updated At</th>
                            {role === "superadmin" && <th scope="col" className="text-center">Delete</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {admins.map((admin) => (
                            <tr key={admin.id}>
                                <td className="text-center align-middle">{admin.attributes.admin_email}</td>
                                <td className="text-center align-middle">{admin.attributes.role}</td>
                                <td className="text-center align-middle">{new Date(admin.attributes.createdAt).toLocaleString()}</td>
                                <td className="text-center align-middle">{new Date(admin.attributes.updatedAt).toLocaleString()}</td>
                                {role === "superadmin" && (
                                    <td className="d-flex justify-content-center align-items-center">
                                        {JSON.parse(sessionStorage.getItem("stytch-user"))?.emails[0]?.email === admin.attributes.admin_email ? (
                                            <>
                                                <button
                                                    className="btn btn-link text-muted p-0"
                                                    style={{ fontSize: "1.5rem", cursor: "not-allowed" }}
                                                    onClick={(e) => e.preventDefault()}
                                                    disabled
                                                >
                                                    <FaRegTrashAlt />
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button
                                                    onClick={() => handleDelete(admin.id)}
                                                    className="btn btn-link text-danger p-0"
                                                    style={{ fontSize: "1.5rem" }}
                                                >
                                                    <FaRegTrashAlt />
                                                </button>
                                            </>
                                        )}
                                    </td>
                                )}

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
