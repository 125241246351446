import React from 'react';
import { DashboardSidebar } from '../../components/dashboard/dashboardSidebar';
import { fetchUnpopulatedExperts } from '../../services/ExpertService';
import { useConvertJSONToTableData } from '../../utils/hooks/dashboardHooks';
import { transformDataFieldsToNames } from '../../utils/transformData';
import DashboardTable from '../../components/dashboard/tables/DashboardTable';
import { downloadExpertTable } from '../../services/adminServices/downloadTable';
import { useLocaleStore } from '../../store';
import { getTranslation } from '../../dictionary';
import { Navigate } from 'react-router-dom';
import { useIsAdmin } from '../../utils/adminChecker';

export default function DashboardExperts() {
  const { tableHeaders, tableData } = useConvertJSONToTableData(fetchUnpopulatedExperts);
  const transformedData = transformDataFieldsToNames(tableData, [12]);
  const language = useLocaleStore((state) => state.language);
  const { isAdmin, loading } = useIsAdmin();

  if (!isAdmin && !loading) {
    return <Navigate to="/" />;
  }

  return (
    <div className="d-flex">
      <DashboardSidebar />
      <div className="flex-grow-1 pt-5 pad-b">
        <DashboardTable tableHeaders={tableHeaders} tableData={transformedData} title={getTranslation({ key: 'dashboard_expert', language })} downloadTable={downloadExpertTable} pageType="experts" />
      </div>
    </div>
  );
}
