import React from 'react';
import { DashboardSidebar } from '../../components/dashboard/dashboardSidebar';
import { useConvertJSONToTableData } from '../../utils/hooks/dashboardHooks';
import { fetchUnpopulatedStakeholders } from '../../services/StakeholderService';
import { transformDataFieldsToNames } from '../../utils/transformData';
import DashboardTable from '../../components/dashboard/tables/DashboardTable';
import { downloadStakeholderTable } from '../../services/adminServices/downloadTable';
import { useLocaleStore } from '../../store';
import { getTranslation } from '../../dictionary';
import { useIsAdmin } from '../../utils/adminChecker';
import { Navigate } from 'react-router-dom';

export default function DashboardStakeholders() {
  const { tableHeaders, tableData } = useConvertJSONToTableData(fetchUnpopulatedStakeholders);
  const transformedData = transformDataFieldsToNames(tableData, [17, 18, 19, 20, 21]);
  const language = useLocaleStore((state) => state.language);
  const { isAdmin, loading } = useIsAdmin();

  if (!isAdmin && !loading) {
    return <Navigate to="/" />;
  }

  return (
    <div className="d-flex">
      <DashboardSidebar />
      <div className="flex-grow-1 pt-5 pad-b">
        <DashboardTable tableHeaders={tableHeaders} tableData={transformedData} title={getTranslation({ key: 'dashboard_stakeholder', language })} downloadTable={downloadStakeholderTable} pageType="stakeholders" />
      </div>
    </div>
  );
}
