import { getTranslation } from '../../../dictionary';
import { useLocaleStore } from '../../../store';
import LinkScrollTop from '../../LinkScrollTop';
import DashboardButton from './DashboardButton';

const DashboardNavigation = () => {
  const language = useLocaleStore((state) => state.language);

  return (
    <div
      className="d-flex flex-row flex-wrap justify-content-center gap-5"
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '1.5rem', // Default for desktop view
      }}
    >
      <LinkScrollTop to="/dashboard/indicators">
        <DashboardButton label={getTranslation({ key: 'indicators', language })} />
      </LinkScrollTop>
      <LinkScrollTop to="/dashboard/experts">
        <DashboardButton label={getTranslation({ key: 'experts', language })} />
      </LinkScrollTop>
      <LinkScrollTop to="/dashboard/initiatives">
        <DashboardButton label={getTranslation({ key: 'initiatives', language })} />
      </LinkScrollTop>
      <LinkScrollTop to="/dashboard/stakeholders">
        <DashboardButton label={getTranslation({ key: 'stakeholders', language })} />
      </LinkScrollTop>

      {/* Media Query for Mobile */}
      <style>
        {`
                @media (max-width: 768px) {
                    div.d-flex {
                        flex-direction: column !important;
                        gap: 0.25rem !important;
                    }
                }
                `}
      </style>
    </div>
  );
};

export default DashboardNavigation;
