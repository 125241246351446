import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import { ModalContent } from './TrendEdit';
import { useIsAdmin } from '../utils/adminChecker';

function EditButton({ datasets, indicator, language, style, group }) {
  const [open, setOpen] = useState(false);
  const isAdmin = useIsAdmin();

  return (
    <>
      {isAdmin && (
        <>
          <IconButton onClick={() => setOpen(true)} style={style}>
            <EditIcon />
          </IconButton>
          <ModalContent
            data={datasets}
            indicator={indicator}
            open={open}
            setOpen={setOpen}
            language={language}
            group={group}
          />
        </>
      )}
    </>
  );
}

export default EditButton;
