import React, { useState } from 'react';
import { FaRegChartBar } from "react-icons/fa";
import { LuLayoutDashboard } from "react-icons/lu";
import { GrStakeholder, GrUserExpert } from "react-icons/gr";
import { MdLightbulbOutline } from "react-icons/md";
import { getTranslation } from '../../dictionary';
import { useLocaleStore } from '../../store';


export const DashboardSidebar = () => {
    const language = useLocaleStore((state) => state.language);
    const [isCollapsed, setIsCollapsed] = useState(false);


    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };


    return (
        <div className={`bg-light border-end p-3 d-lg-flex d-none flex-column ${isCollapsed ? 'collapsed-sidebar' : ''}`}
            style={{
                width: isCollapsed ? '80px' : '250px',
                transition: 'width 0.3s',
                minHeight: '100vh',
            }}>
            <button className="btn btn-secondary mb-3" onClick={toggleSidebar} style={{ transition: 'transform 0.3s' }}>
                {isCollapsed ? (
                    <i className="bi bi-arrow-bar-right"></i>
                ) : (
                    <i className="bi bi-arrow-bar-left"></i>
                )}
            </button>
            <ul className="d-flex flex-column gap-1 list-unstyled">
                <li className=" d-flex aling-items-center cursor-pointer" onClick={() => {
                    window.location.href = '/dashboard';
                }}>
                    <span className={`btn btn-link`}>
                        <LuLayoutDashboard size={24} />
                    </span>
                    <span className={`my-auto ${isCollapsed ? 'd-none' : ''}`}>
                        {getTranslation({ key: 'dashboard', language })}
                    </span>
                </li>
                <li className=" d-flex aling-items-center cursor-pointer" onClick={() => {
                    window.location.href = '/dashboard/indicators';
                }}>
                    <span className={`btn btn-link`}>
                        <FaRegChartBar size={24} />
                    </span>
                    <span className={`my-auto ${isCollapsed ? 'd-none' : ''}`}>
                        {getTranslation({ key: 'indicators', language })}
                    </span>
                </li>
                <li className=" d-flex aling-items-center cursor-pointer" onClick={() => {
                    window.location.href = '/dashboard/experts';
                }}>
                    <span className={`btn btn-link`}>
                        <GrUserExpert size={24} />
                    </span>
                    <span className={`my-auto ${isCollapsed ? 'd-none' : ''}`}>
                        {getTranslation({ key: 'experts', language })}
                    </span>
                </li>
                <li className=" d-flex aling-items-center cursor-pointer" onClick={() => {
                    window.location.href = '/dashboard/initiatives';
                }}>
                    <span className={`btn btn-link`}>
                        <MdLightbulbOutline size={24} />
                    </span>
                    <span className={`my-auto ${isCollapsed ? 'd-none' : ''}`}>
                        {getTranslation({ key: 'initiatives', language })}
                    </span>
                </li>
                <li className=" d-flex aling-items-center cursor-pointer" onClick={() => {
                    window.location.href = '/dashboard/stakeholders';
                }}>
                    <span className={`btn btn-link`}>
                        <GrStakeholder size={24} />
                    </span>
                    <span className={`my-auto ${isCollapsed ? 'd-none' : ''}`}>
                        {getTranslation({ key: 'stakeholders', language })}
                    </span>
                </li>
            </ul>
        </div>
    )
}