import { fetchExperts } from "../ExpertService";
import { fetchIndicators } from "../IndicatorService";
import { fetchInitiatives } from "../InitiativeService";
import { fetchStakeholders } from "../StakeholderService";
import * as XLSX from 'xlsx';

export const downloadExpertTable = async () => {
    try {
        const experts = await fetchExperts();

        if (experts.length === 0) {
            console.warn("No data available to export.");
            return;
        }

        const worksheet = createExcelSheet(experts);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Experts');

        XLSX.writeFile(workbook, 'experts.xlsx');
    } catch (error) {
        console.error("Error downloading expert table:", error);
    }
};

export const downloadIndicatorTable = async () => {
    try {
        const indicators = await fetchIndicators();

        if (indicators.length === 0) {
            console.warn("No data available to export.");
            return;
        }

        const worksheet = createExcelSheet(indicators);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Indicators');

        XLSX.writeFile(workbook, 'indicators.xlsx');
    } catch (error) {
        console.error("Error downloading indicator table:", error);
    }
}

export const downloadInitiativeTable = async () => {
    try {
        const initiatives = await fetchInitiatives();

        if (initiatives.length === 0) {
            console.warn("No data available to export.");
            return;
        }

        const worksheet = createExcelSheet(initiatives);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Initiatives');

        XLSX.writeFile(workbook, 'initiatives.xlsx');
    } catch (error) {
        console.error("Error downloading initiative table:", error);
    }
}

export const downloadStakeholderTable = async () => {
    try {
        const stakeholders = await fetchStakeholders();

        if (stakeholders.length === 0) {
            console.warn("No data available to export.");
            return;
        }

        const worksheet = createExcelSheet(stakeholders);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Stakeholders');

        XLSX.writeFile(workbook, 'stakeholders.xlsx');
    } catch (error) {
        console.error("Error downloading stakeholder table:", error);
    }
}

const createExcelSheet = (data) => {
    const headers = Object.keys(data[0]);

    const rows = data.map((row) => {
        return headers.map(header => {
            const value = row[header] || '';

            if (typeof value === 'object' && value !== null) {
                try {
                    return JSON.stringify(value, null, 2);
                } catch (error) {
                    return JSON.stringify(value);
                }
            }

            return value;
        });
    });

    const sheetData = [headers, ...rows];

    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

    worksheet['!cols'] = headers.map(() => ({ width: 20 }));

    return worksheet;
};

