import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import searchIcon from '../assets/images/search.svg';
import InitiativeCard from '../components/InitiativeCard';
import InterestOverlay from '../components/InterestOverlay';
import LinkScrollTop from '../components/LinkScrollTop';
import { getTranslation } from '../dictionary';
import { initiativesQueryOptions } from '../services/InitiativeService';
import { useLocaleStore } from '../store';

function Initiatives() {
  const language = useLocaleStore((state) => state.language);
  const [interestsSelected, setInterestsSelected] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchIsOpen, setSearchIsOpen] = useState(false);

  const { data: initiatives } = useQuery({
    ...initiativesQueryOptions,
    refetchOnWindowFocus: false,
  });

  const interestsStats = {};
  const exclude = ['id'];

  initiatives?.forEach((e) => {
    e.interest.forEach((interest) => {
      const interestName = typeof interest === 'string' ? interest : interest.name;
      if (!exclude.includes(interestName)) {
        try {
          if (!interestsStats.hasOwnProperty(interestName)) {
            interestsStats[interestName] = {
              count: 0,
              name_nl: interest.name_nl,
              name_en: interest.name_en,
            };
          }
          interestsStats[interestName].count++;
        } catch (err) {
          interestsStats[interestName] = {
            count: 0,
            name_nl: interestName,
            name_en: interestName,
          };
        }
      }
    });
  });

  const updateFilters = (type, value) => {
    if (type === null) {
      setInterestsSelected([]);
      setSearchText('');
      setSearchIsOpen(false);
    } else if (type === 'search') {
      setSearchText(value);
    } else if (type === 'addInterest') {
      const interestName = typeof value === 'string' ? value : value.name;
      setInterestsSelected((prevState) => prevState.concat([interestName]));
    } else if (type === 'removeInterest') {
      const interestName = typeof value === 'string' ? value : value.name;
      setInterestsSelected((prevState) => prevState.filter((e) => e !== interestName));
    }
  };

  const getFilteredInitiatives = () => {
    return initiatives
      ?.filter((d) => {
        if (interestsSelected.length === 0) {
          return true;
        } else {
          return interestsSelected.every((v) => d.interest.some((interest) => interest.name === v));
        }
      })
      .filter((d) => {
        if (searchText.trim() === '') {
          return true;
        }
        return d.name.toLowerCase().indexOf(searchText.toLowerCase().trim()) !== -1;
      })
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  };  

  return (
    <>
      <section className="indicators-intro pad-small">
        <div className="container-fluid">
          <div className="row pb-5">
            <div className="col-12">
              <div className="large-small-header-combo">
                <h2>{getTranslation({ key: 'initiatives', language })}</h2>
              </div>
              {language === 'nl' ? (
                <p className="font-serif-l">
                  Op deze pagina vindt je verschillende afgelopen en lopende initiatieven die streven naar een duurzaam
                  Leuvens voedselsysteem. Heb je zelf een initiatief op poten gezet en zou je deze graag in onderstaande
                  lijst laten weergeven? Voeg dan{' '}
                  <LinkScrollTop className="hover-white u" to={`/sign-up`}>
                    hier
                  </LinkScrollTop>{' '}
                  je informatie toe.
                </p>
              ) : (
                <p className="font-serif-l">
                  On this page you will find several past and ongoing initiatives that strive for a sustainable Leuven
                  food system. Have you launched an initiative yourself and would like to have it included in the list
                  below? Then add your information{' '}
                  <LinkScrollTop className="hover-white u" to={`/sign-up`}>
                    here
                  </LinkScrollTop>
                  .{' '}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>

      <section id="content-overlay-box">
        <InterestOverlay
          interestsStats={interestsStats}
          interestsSelected={interestsSelected}
          updateFilters={updateFilters}
        />

        <div id="content">
          <section className="stakeholders-cards pad-small">
            <div className="container-fluid pad-small-b">
              <div className="row">
                <div className="col-12">
                  {searchIsOpen ? (
                    <div className="title-and-search open">
                      <div className="input-wrapper mr-2">
                        <img src={searchIcon} className="mr-2" alt="Search Icon" />
                        <input
                          type="text"
                          placeholder={getTranslation({
                            key: 'initiative_name',
                            language,
                          })}
                          className="font-strong-ll"
                          onChange={(e) => updateFilters('search', e.target.value)}
                          autoFocus
                        />
                      </div>
                      <span onClick={() => updateFilters(null)} className="font-strong-ll cursor-pointer">
                        &times;
                      </span>
                    </div>
                  ) : (
                    <div className="title-and-search">
                      <h2 className="mb-0">
                        {interestsSelected.length === 0 ? (
                          getTranslation({ key: 'all_initiatives', language })
                        ) : (
                          <a
                            href="#title-and-search"
                            onClick={(e) => {
                              e.preventDefault();
                              updateFilters(null);
                            }}
                          >
                            {getTranslation({
                              key: 'back_to_all_initiatives',
                              language,
                            })}
                            {getTranslation({ key: '', language })}
                          </a>
                        )}
                      </h2>
                      <button
                        className="open-search-link bg-transparent border-0 text-body d-inline-flex align-items-center p-0"
                        onClick={() => setSearchIsOpen(true)}
                      >
                        <img src={searchIcon} alt="Search Icon" />
                        <span className="d-none d-md-inline ml-2">
                          &nbsp;{getTranslation({ key: 'search', language })}
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                {getFilteredInitiatives()?.map((d, i) => {
                  return <InitiativeCard d={d} key={`initiative-${i}`} />;
                })}
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}

export default Initiatives;
