import { Navigate } from 'react-router-dom';
import { useIsAdmin } from '../../utils/adminChecker';
import React from 'react';
import { getTranslation } from '../../dictionary';
import { useLocaleStore } from '../../store';
import DashboardNavigation from '../../components/dashboard/downloadTables/DashboardNavigation';
import { AddAdminForm } from '../../components/dashboard/manageAdmins/AddAdminForm';
import { AdminsList } from '../../components/dashboard/manageAdmins/AdminsList';
import { DashboardSidebar } from '../../components/dashboard/dashboardSidebar';

export default function Dashboard() {
    const language = useLocaleStore((state) => state.language);
    const { isAdmin, loading, role } = useIsAdmin();

    if (!isAdmin && !loading) {
        return <Navigate to="/" />;
    }

    return (
        <div className="d-flex">
            <DashboardSidebar />
            <div className="flex-grow-1 pt-5 pad-b">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="mb-space">{getTranslation({ key: 'dashboard_ecofoodmap', language })}</h2>
                        </div>
                    </div>
                    <div>
                        <DashboardNavigation />
                    </div>
                    {role === 'superadmin' && (
                        <div>
                            <AddAdminForm />
                        </div>
                    )}
                    <div>
                        <AdminsList />
                    </div>
                </div>
            </div>
        </div>
    );
}
