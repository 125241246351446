import React, { useState, useRef, useEffect } from 'react';
import { IoDownloadOutline } from 'react-icons/io5';
import RowDataModal from './RowDataModal.js';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure you have Bootstrap imported
import { Grid } from 'gridjs-react';
import 'gridjs/dist/theme/mermaid.min.css';

export default function DashboardTable({
  tableHeaders,
  title,
  tableData,
  error = null,
  downloadTable = undefined,
  pageType,
}) {
  const [downloading, setDownloading] = useState(false);
  const [showModal, setShowModal] = useState(false); // To control modal visibility
  const [isAddIndicatorModal, setIsAddIndicatorModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({}); // To store the row data being edited
  const [isEditing, setIsEditing] = useState(false); // To control edit toggle
  const gridRef = useRef();

  const handleAddIndicatorModal = () => {
    setIsAddIndicatorModal(true);  // Show modal in 'Add Indicator' mode
    setSelectedRowData({});  // Reset data if it's a new indicator
    setShowModal(true);
  };

  const handleDownloadClick = async () => {
    if (downloadTable) {
      try {
        setDownloading(true);
        await downloadTable();
      } catch (error) {
        console.error('Error downloading table:', error);
      } finally {
        setDownloading(false);
      }
    }
  };

  useEffect(() => {

    const handleRowClick = (row) => {
      const rowData = tableHeaders.reduce((acc, header, idx) => {
        acc[header] = row[idx];
        return acc;
      }, {});

      console.log('Row data:', rowData);

      setSelectedRowData(rowData); // Now selectedRowData will be an object
      setShowModal(true);
    };

    const gridjsInstance = gridRef.current.getInstance();
    gridjsInstance.on('rowClick', (...args) => {
      const row = [];
      for (let i = 0; i < tableHeaders.length; i++) {
        row.push(args[1]._cells[i].data);
      }
      handleRowClick(row);
    });
  }, [tableHeaders.length, tableHeaders])

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedRowData({});
    setIsEditing(false); // Reset edit mode when closing
    setIsAddIndicatorModal(false);
  };
  return (
    <>
      {error && <div className="alert alert-danger text-center">Error: {error.message || 'Something went wrong'}</div>}

      {/* Modal for editing row data */}
      <RowDataModal
        showModal={showModal}
        isEditing={isEditing}
        selectedRowData={selectedRowData}
        handleCloseModal={handleCloseModal}
        setIsEditing={setIsEditing}
        setSelectedRowData={setSelectedRowData}
        pageType={pageType}
        isAddIndicatorModal={isAddIndicatorModal}
        orderedHeaders={tableHeaders}
      />
      <div className="container-fluid">
        <h1 className="mb-4 text-primary">{title}</h1>
        <div className="table-responsive" style={{ overflowX: 'auto' }}>

          <div className="d-flex justify-content-between align-items-center mb-3">
            <button className="btn btn-outline-primary" onClick={handleDownloadClick} disabled={downloading}>
              <IoDownloadOutline size={20} /> Download
            </button>
            {pageType === 'indicators' && (
              <button type="button" className="button m rounded-3" onClick={handleAddIndicatorModal}>
                Add Indicator
              </button>
            )}
          </div>
          <Grid
            ref={gridRef}
            data={tableData}
            columns={tableHeaders.map((header) => ({
              name: header,
              // formatter: (cell) => {
              //   if (header === 'interest') {
              //     try {
              //       const interestObject = JSON.parse(cell);

              //       const interests = interestObject.data.map((item) => item.attributes.name_nl);

              //       return interests.join(', ');
              //     } catch (error) {
              //       console.error('Error parsing interest data:', error);
              //       return 'Invalid data';
              //     }
              //   }
              //   return cell;
              // },
            }))}
            pagination={{
              limit: 10,
            }}
            sort={true}
            search={true}
            style={{
              table: {
                border: '3px solid #ccc',
                width: '100%',
                tableLayout: 'auto',
              },
              th: {
                borderBottom: '3px solid #ccc',
                textAlign: 'center',
                fontSize: 'clamp(12px, 1.5vw, 16px)',
                maxWidth: '200px',
                minWidth: '100px',
              },
              td: {
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                '@media (max-width: 768px)': {
                  whiteSpace: 'normal',
                },
                fontSize: 'clamp(12px, 1.2vw, 14px)',
                maxWidth: '200px',
                minWidth: '100px',
              },
            }}
          />

        </div>
      </div>
    </>
  );
}
