import { useState, useEffect } from 'react';

/**
 * Custom React hook to fetch data using a provided fetch function,
 * and convert the raw JSON data into table-friendly headers and rows.
 *
 * @param {Function} fetchFunction - An asynchronous function that fetches data (e.g., from an API).
 *                                   It must return an array of objects where keys are column names
 *                                   and values are the corresponding cell values.
 *
 * @returns {Object} - Returns an object containing:
 *   @property {Array<string>} tableHeaders - An array of column headers derived from the keys of the first object in the data.
 *   @property {Array<Array<string>>} tableData - A 2D array where each inner array represents a row of values.
 *                                                Nested objects are converted to strings using JSON.stringify.
 *   @property {Error|null} error - The error object if fetching data fails, otherwise null.
 *   @property {boolean} loading - A boolean indicating if the data is still being fetched.
 *
 * @example
 * // Example usage
 * const { tableHeaders, tableData, error, loading } = useConvertJSONToTableData(fetchDataFunction);
 *
 * // Fetch function example
 * async function fetchDataFunction() {
 *   return [
 *     { name: "John", age: 30, interest: '{"data":[{"attributes":{"name_en":"Interest 1"}},{"attributes":{"name_en":"Interest 2"}}]}' },
 *     { name: "Doe", age: 25, interest: '{"data":[{"attributes":{"name_en":"Interest 3"}}]}' }
 *   ];
 * }
 *
 * // Result
 * tableHeaders = ["name", "age", "interest"];
 * tableData = [
 *   ["John", "30", "Interest 1, Interest 2"],
 *   ["Doe", "25", "Interest 3"]
 * ];
 */
export const useConvertJSONToTableData = (fetchFunction) => {
    const [tableHeaders, setTableHeaders] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                const rawData = await fetchFunction();
                if (rawData && rawData.length > 0) {
                    setTableHeaders(Object.keys(rawData[0]));
                    setTableData(
                        rawData.map((row) =>
                            Object.entries(row).map(([key, value]) => {
                                if (key === 'interest') {
                                    try {
                                        const interestObject =
                                            typeof value === 'string' ? JSON.parse(value) : value;

                                        return interestObject.data
                                            .map((item) => item.attributes.name_en)
                                            .join(', ');
                                    } catch (err) {
                                        console.error('Error parsing interest:', err);
                                        return '';
                                    }
                                }

                                if (key === 'opening_hours') {
                                    try {
                                        const openingHoursObject =
                                            typeof value === 'string' ? JSON.parse(value) : value;

                                        return Object.entries(openingHoursObject)
                                            .map(([day, hours]) => `${capitalize(day)}: ${hours}`)
                                            .join(', ');
                                    } catch (err) {
                                        console.error('Error parsing opening_hours:', err);
                                        return '';
                                    }
                                }

                                return typeof value === 'object' && value !== null
                                    ? JSON.stringify(value)
                                    : value;
                            })
                        )
                    );
                } else {
                    setTableHeaders([]);
                    setTableData([]);
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [fetchFunction]);

    return { tableHeaders, tableData, error, loading };
};

// Helper function to capitalize the first letter of a word
const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);
