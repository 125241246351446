import { useStytch } from '@stytch/react';
import React, { useEffect } from 'react';
import hamburger from '../assets/images/menu-hmb.svg';
import searchIcon from '../assets/images/search-purple.svg';
import LinkScrollTop from '../components/LinkScrollTop';
import Searchbar from '../components/Searchbar';
import { getTranslation } from '../dictionary';
import useStytchUserLoader from '../loaders/useStytchUserLoader';
import { useLocaleStore, useStore } from '../store';
import { openMenuMobile } from '../utils';
import { useIsAdmin } from '../utils/adminChecker';

function Header() {
  const language = useLocaleStore((state) => state.language);
  const setLanguage = useLocaleStore((state) => state.setLanguage);
  const searchOpen = useStore((state) => state.searchOpen);
  const {isAdmin, isLoading} = useIsAdmin();

  const stytchClient = useStytch();
  var { user } = useStytchUserLoader();

  const setSearchOpen = (searchOpen) => {
    useStore.setState({ searchOpen: searchOpen });
  };

  useEffect(() => {
    document.body.classList.toggle('search-open', searchOpen);
  }, [searchOpen]);

  useEffect(() => {
    if (searchOpen) {
      const input = document.querySelector('input.react-autosuggest__input');
      if (input) input.focus();
    }
  }, [searchOpen]);

  return (
    <nav id="header">
      <div className="container-fluid overflow-visible">
        <div className="row">
          <div className="col-12 wrapper">
            <div className={`left ${searchOpen ? ' search' : ''}`}>
              <LinkScrollTop className="font-strong-m color-c2" to={'/'}>
                EcoFoodMap <span className="font-serif-m header-serif">Leuven</span>
              </LinkScrollTop>
            </div>

            {!searchOpen && (
              <div className="right-mobile">
                <span className="mr-4" onClick={() => setSearchOpen(true)}>
                  <img src={searchIcon} alt="searchIcon" />
                </span>

                <img
                  onClick={() => {
                    openMenuMobile();
                  }}
                  src={hamburger}
                  alt="hamburgerIcon"
                  className="cursor-pointer"
                />
              </div>
            )}

            {!searchOpen && (
              <div className="right">
                <LinkScrollTop to={'/indicators'}>{getTranslation({ key: 'indicators', language })}</LinkScrollTop>

                <LinkScrollTop to={'/experts'}>{getTranslation({ key: 'experts', language })}</LinkScrollTop>

                <LinkScrollTop to={'/initiatives'}>{getTranslation({ key: 'initiatives', language })}</LinkScrollTop>

                <LinkScrollTop to={'/stakeholders'}>{getTranslation({ key: 'stakeholders', language })}</LinkScrollTop>

                <LinkScrollTop to={'/about'}>{getTranslation({ key: 'about', language })}</LinkScrollTop>

                {(isAdmin && !isLoading) && <LinkScrollTop to={'/dashboard'}>{getTranslation({ key: 'dashboard', language })}</LinkScrollTop>}

                {!user && <LinkScrollTop to={'/sign-up'}>{getTranslation({ key: 'doe_mee', language })}</LinkScrollTop>}

                {user && <LinkScrollTop to={'/data'}>{getTranslation({ key: 'user_info', language })}</LinkScrollTop>}

                {user && (
                  <a
                    href="/"
                    className="item"
                    onClick={() => {
                      sessionStorage.removeItem('stytch-user');
                      sessionStorage.removeItem('stytch-session');
                      stytchClient.session.revoke();
                    }}
                  >
                    Logout
                  </a>
                )}

                <span className="mx-4 pl-2 cursor-pointer">
                  <span
                    className={`header-language-el ${language === 'en' ? 'active' : ''}`}
                    onClick={() => setLanguage('en')}
                  >
                    EN
                  </span>
                  {' · '}
                  <span
                    className={`header-language-el ${language === 'nl' ? 'active' : ''}`}
                    onClick={() => setLanguage('nl')}
                  >
                    NL
                  </span>
                </span>

                <span onClick={() => setSearchOpen(true)}>
                  <img src={searchIcon} alt="searchIcon" />
                </span>
              </div>
            )}

            {searchOpen && (
              <div className="general-search">
                <img src={searchIcon} alt="searchIcon" />
                {/*<input type="text" placeholder="Search stakeholders…" />*/}
                <div className="search-field-wrapper">
                  <Searchbar />
                </div>
                <a className="close" href="#general-search" onClick={() => setSearchOpen(false)}>
                  &times;
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
