const DashboardButton = ({ label, onClick, prefixIcon: PrefixIcon }) => {
    return (
        <button className="button m rounded-3" onClick={onClick}>
            {PrefixIcon && <span className="pe-2">{<PrefixIcon />}</span>}
            {label}
        </button>
    );
};

export default DashboardButton;
