import React, { useState } from 'react';
import openSidebarButton from '../assets/images/menu-sidebar-open.svg';
import { getTranslation } from '../dictionary';
import { useLocaleStore } from '../store';
import { toggleSidebar } from '../utils';

const InterestOverlay = ({ interestsStats, interestsSelected, updateFilters }) => {
  const language = useLocaleStore((state) => state.language);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebarAndMenu = () => {
    setSidebarOpen(!sidebarOpen);
    toggleSidebar();
  };

  if (Object.keys(interestsStats).length <= 0) return null;

  const sortedInterestIds = Object.keys(interestsStats).sort((a, b) => {
    const nameA = interestsStats[a][`name_${language}`]?.toLowerCase() || '';
    const nameB = interestsStats[b][`name_${language}`]?.toLowerCase() || '';
    return nameA.localeCompare(nameB);
  });

  return (
    <div id="overlay">
      <div id="sticky" className="w-100">
        <button id="toggleSidebar" onClick={toggleSidebarAndMenu}>
          {getTranslation({ key: 'filters', language })}&nbsp;
          <img id="sidebarIcon" src={openSidebarButton} alt="HamburgerMenu" />
        </button>
        <div id="sidebarList">
          <ul>
            {sortedInterestIds.map((interestId) => {
              const { name_nl, name_en } = interestsStats[interestId];
              const interestName = language === 'nl' ? name_nl : name_en;
              const isSelected = interestsSelected.includes(interestId);
              const toggleInterest = () => updateFilters(isSelected ? 'removeInterest' : 'addInterest', interestId);

              return (
                <li
                  key={interestId}
                  className={`filterSelected cursor-pointer border-bottom p-2`}
                  onClick={toggleInterest}
                >
                  {isSelected ? '☒' : '☐'} {interestName}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default InterestOverlay;
