import { useEffect, useState } from 'react';
import axios from '../axios';
import useStytchUserLoader from '../loaders/useStytchUserLoader';


/**
 * Custom Hook: useIsAdmin
 *
 * This hook determines if the currently authenticated user is an admin.
 * It uses the user object provided by the `useStytchUserLoader` hook and checks 
 * the user's email against the list of admin emails retrieved from the backend.
 * 
 * Key Features:
 * - Asynchronously validates if the user is an admin by querying the backend.
 * - Returns the admin status (`isAdmin`) and a loading state (`loading`) to indicate 
 *   whether the check is in progress.
 * - Prevents state updates if the component is unmounted during the async operation.
 *
 * Usage:
 * ```jsx
 * const { isAdmin, loading } = useIsAdmin();
 * 
 * if (loading) {
 *   return <LoadingSpinner />;
 * }
 * 
 * return isAdmin ? <AdminDashboard /> : <Navigate to="/" />;
 * ```
 *
 * Returns:
 * - `isAdmin` (boolean): Indicates whether the user is an admin.
 * - `loading` (boolean): Indicates if the admin validation is still in progress.
 * 
 * Dependencies:
 * - `useStytchUserLoader`: A hook providing the authenticated user object.
 * - `axios`: Used to send HTTP requests to the backend.
 * 
 * Example Backend API Endpoint (for validation):
 * - GET `/admins?filters[admin_email][$eq]={userEmail}`
 *   - Expected response: `{ data: [{ attributes: { admin_email: 'admin@example.com' } }] }`
 *
 * Notes:
 * - Ensure the backend API is properly configured to respond with admin user data.
 * - The `isMounted` check prevents React state updates after the component unmounts.
 */


export const useIsAdmin = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [role, setRole] = useState('');
    const [loading, setLoading] = useState(true);
    const { user } = useStytchUserLoader();

    useEffect(() => {
        let isMounted = true;

        const checkIfAdmin = async () => {
            setLoading(true);
            if (!user?.emails?.[0]?.email) {
                if (isMounted) {
                    setIsAdmin(false);
                    setLoading(false);
                }
                return;
            }

            try {
                const userEmail = user.emails[0].email;
                const result = await axios.get(`/admins?filters[admin_email][$eq]=${userEmail}`);
                const admins = result?.data?.data || [];

                const isAdminUser = admins[0]?.attributes?.admin_email === userEmail;
                setRole(admins[0]?.attributes?.role ?? '');

                if (isMounted) {
                    setIsAdmin(isAdminUser);
                }
            } catch (error) {
                console.error('Error checking admin:', error);
                if (isMounted) {
                    setIsAdmin(false);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        checkIfAdmin();

        return () => {
            isMounted = false;
        };
    }, [user]);

    return { isAdmin, loading, role };
};
