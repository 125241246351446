import { useState } from "react";
import { IoMdPersonAdd } from 'react-icons/io';
import { createAdmin } from "../../../services/adminServices/manageAdmins";

export const AddAdminForm = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [formError, setFormError] = useState('');

    const validateEmail = (email) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setEmailError('');
        setFormError('');

        if (!email) {
            setEmailError('Email is required');
            return;
        }

        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address');
            return;
        }

        createAdmin({ data: { admin_email: email } })
            .then(() => {
                setEmail('');
                window.location.reload();
            })
            .catch((error) => {
                if (error.response?.status === 400) {
                    setEmailError(error.response.data.error.message);
                    return;
                }
                setFormError('An error occurred while creating the admin. Please try again.');
            });
    };

    return (
        <div className="row">
            <div className="col-12">
                <h3>Add Admin</h3>
                <form onSubmit={handleSubmit} noValidate={true}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <div className="d-flex gap-2">
                            <div className="flex-grow-1">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="john@doe.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {emailError && <div className="text-danger mt-1">{emailError}</div>}
                            </div>
                            <button type="submit" className="button m rounded-3">
                                <span className="pe-2">{<IoMdPersonAdd />}</span>
                                Add admin
                            </button>
                        </div>
                    </div>
                </form>
                {formError && <div className="text-danger mt-3">{formError}</div>}
            </div>
        </div>
    );
};
