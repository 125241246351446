import { createInstance, MatomoProvider } from '@jonkoops/matomo-tracker-react';
import { createTheme, ThemeProvider } from '@mui/material';
import { StytchProvider } from '@stytch/react';
import { StytchUIClient } from '@stytch/vanilla-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import TokenAuthenticator from './components/TokenAuthenticator';
import ErrorPage from './error-page';
import './index.scss';
import About from './pages/About';
import Expert from './pages/Expert';
import Experts from './pages/Experts';
import Home from './pages/Home';
import IndicatorPage from './pages/IndicatorPage';
import Indicators from './pages/Indicators';
import IndicatorsSubset from './pages/IndicatorsSubset';
import Initiative from './pages/Initiative';
import Initiatives from './pages/Initiatives';
import Loggedin from './pages/LoggedIn';
import Privacy from './pages/Privacy';
import Root from './pages/Root';
import Signup from './pages/Signup';
import Stakeholder from './pages/Stakeholder';
import Stakeholders from './pages/Stakeholders';
import Userdata from './pages/UserData';
import reportWebVitals from './reportWebVitals';
import Dashboard from './pages/dashboard/Dashboard';
import DashboardExperts from './pages/dashboard/DashboardExperts';
import DashboardIndicators from './pages/dashboard/DashboardIndicators';
import DashboardInitiatives from './pages/dashboard/DashboardInitiatives';
import DashboardStakeholders from './pages/dashboard/DashboardStakeholders';

const instance = createInstance({
  urlBase: 'https://leuven2030.matomo.cloud/',
  siteId: 4,
  trackerUrl: 'https://leuven2030.matomo.cloud/matomo.php',
  /*urlBase: 'https://ecofoodmapv2vercelapp.matomo.cloud/',
  siteId: 1,
  trackerUrl: 'https://ecofoodmapv2vercelapp.matomo.cloud/matomo.php',*/
  linkTracking: true,
  /*configurations: {
    setSecureCookie: true
  }*/
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      {
        path: 'indicators',
        element: <Indicators />,
      },
      {
        path: 'indicators/:subsetKey/:subsetValue',
        element: <IndicatorsSubset />,
        loader: ({ params }) => ({ subsetKey: params.subsetKey, subsetValue: params.subsetValue }),
      },
      {
        path: 'indicators/:id',
        element: <IndicatorPage />,
        loader: ({ params }) => ({ id: params.id }),
      },
      {
        path: 'stakeholders',
        element: <Stakeholders />,
      },
      {
        path: 'stakeholders/:id',
        element: <Stakeholder />,
        loader: ({ params }) => ({ id: params.id }),
      },
      {
        path: 'experts',
        element: <Experts />,
      },
      {
        path: 'experts/:id',
        element: <Expert />,
        loader: ({ params }) => ({ id: params.id }),
      },
      {
        path: 'initiatives',
        element: <Initiatives />,
      },
      {
        path: 'initiatives/:id',
        element: <Initiative />,
        loader: ({ params }) => ({ id: params.id }),
      },
      {
        path: 'about',
        element: <About />,
      },
      {
        path: 'privacy_cookies',
        element: <Privacy />,
      },
      {
        path: 'sign-up',
        element: <Signup />,
      },
      {
        path: 'logged-in',
        element: <Loggedin />,
      },
      {
        path: 'data',
        element: <Userdata />,
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'dashboard/experts',
        element: <DashboardExperts />,
      }, 
      {
        path: 'dashboard/indicators',
        element: <DashboardIndicators />,
      }, 
      {
        path: 'dashboard/initiatives',
        element: <DashboardInitiatives />,
      }, 
      {
        path: 'dashboard/stakeholders',
        element: <DashboardStakeholders />,
      },
      // {
      //   path: 'suggestion/:type/:id',
      //   element: <Suggestion />,
      //   loader: SuggestionLoader,
      // },
    ],
  },
]);

const stytch = new StytchUIClient(process.env.REACT_APP_STYTCH_PUBLIC_TOKEN);

const queryClient = new QueryClient();
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#5100FF',
    },
    secondary: {
      main: '#9c27b0',
    },
  },
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <StytchProvider stytch={stytch}>
          <TokenAuthenticator />
          <MatomoProvider value={instance}>
            <RouterProvider router={router} />
          </MatomoProvider>
        </StytchProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
