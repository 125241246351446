import React, { useState } from 'react';
import { getTranslation } from '../dictionary';
import { apiUrl } from '../settings';
import { useLocaleStore } from '../store';
import { getPhotoIfPresent } from '../utils';
import LinkScrollTop from './LinkScrollTop';
import UpdateInitiative from './UpdateInitiative';
import { unclaimInitiative } from '../services/InitiativeService';

const InitiativeCard = ({ d, showClaimButton = false }) => {
  const language = useLocaleStore((state) => state.language);
  const location = window.location.pathname;
  const maxWords = 22;

  const translatedDescription = language === 'en' ? d.description_en : d.description;
  const description = translatedDescription
    ? translatedDescription.split(' ').slice(0, maxWords).join(' ')
    : 'No description.';

  const photo = getPhotoIfPresent(d);
  const imgStyle = {
    backgroundImage: `url(${apiUrl?.slice(0, -4)}${photo})`,
    //backgroundSize: 'contain', // Scales the image to fit within the container
    backgroundSize: 'cover', // Scales the image to cover the container
    backgroundPosition: 'center center', // Centers the image within the container
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleCollapsible = () => setIsOpen(!isOpen);

  const handleUnClaim = (event) => {
    event.stopPropagation();
    unclaimInitiative(d.id);
  };

  const renderClaimButton = () => (
    <>
      <div>
        <div className="font-sans-s color-black-50 mt-4">{getTranslation({ key: 'unclaim', language })}</div>

        <div className="font-serif-m mt-1">
          <div>
            <p
              className="color-c3 font-serif-m font-strong-m my-1 cursor-pointer claim-button"
              onClick={(event) => handleUnClaim(event)}
            >
              Unclaim
            </p>
          </div>
        </div>
      </div>
    </>
  )

  const renderInterests = () => {
    // Extract `interest` data, ensuring it is an array
    let interests = d?.interest?.data || d?.interest || [];
    if (!Array.isArray(interests)) {
      interests = [];
    }

    // Handle case where there are no interests
    if (interests.length === 0) {
      return getTranslation({ key: 'no_interests', language });
    }

    const maxInterests = 3;

    // Log the interests for debugging
    console.log('d', d);
    console.log('interests', interests);

    // Process and localize interests
    const displayedInterests = interests.slice(0, maxInterests).map((interest) => {
      // Support for nested `attributes` structure
      if (interest.attributes) {
        return language === 'en' ? interest.attributes.name_en : interest.attributes.name_nl;
      }
      // Fallback for direct `name_en` or `name_nl` properties
      return language === 'en' ? interest.name_en : interest.name_nl;
    });

    // Append a "more" indicator if there are additional interests
    if (interests.length > maxInterests) {
      displayedInterests.push(`${interests.length - maxInterests} ${getTranslation({ key: 'more', language })}`);
    }

    // Return the formatted interest list
    return displayedInterests.join(', ');
  };

  const isAuthenticated = sessionStorage.getItem('stytch-user');
  const isOwner = isAuthenticated && JSON.parse(isAuthenticated)?.user_id === d?.user_id;
  const showUpdateButton = isOwner && location === '/data';

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} className="col-md-6 col-xl-4 card-stakeholder-col">
      <LinkScrollTop className="card-stakeholder" style={{ flex: 1 }} to={`/initiatives/${d.id}`}>
        <header>
          <div className="left">
            <div className="font-sans-s font-700 color-c3">
              {getTranslation({ key: 'initiative', language }).toUpperCase()}
            </div>
            <div className="card-title">{d.name}</div>
            <div className="font-sans-s color-blackish-80">{renderInterests()}</div>
          </div>
          {photo && (
            <div className="right">
              <div className="round-img thumb" style={imgStyle}></div>
            </div>
          )}
        </header>
        <div className="card-body">
          <div className="font-serif-m font-400">{description}</div>
          {showClaimButton && renderClaimButton()}
        </div>
      </LinkScrollTop>
      {showUpdateButton && (
        <button className="font-serif-m font-400" onClick={toggleCollapsible}>
          {isOpen ? getTranslation({ key: 'close', language }) : getTranslation({ key: 'update', language })} &#9660;
        </button>
      )}
      {isOpen && (
        <div className="collapsible-content">
          <UpdateInitiative props={d} setIsOpen={setIsOpen} />
        </div>
      )}
    </div>
  );
};

export default InitiativeCard;
