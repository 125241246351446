import { FormControlLabel, FormGroup, FormLabel, Switch } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { getTranslation } from '../dictionary';
import { useLocaleStore } from '../store';

const RenderSwitches = ({ state, label, handleChange, control, categoryKey, interestList }) => {
  const language = useLocaleStore((state) => state.language);

  const renderLabel = (key) => {
    if (interestList) {
      const interest = interestList.find((interest) => interest.name === key);
      const lable = interest[`name_${language}`];
      return lable;
    }

    return getTranslation({ key: key, categoryKey: categoryKey, language });
  }

  return (
    <>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup>
        {Object.keys(state).map((key) => (
          <Controller
            key={key}
            name={key}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} checked={state[key]} onChange={handleChange} />}
                label={renderLabel(key)}
              />
            )}
          />
        ))}
      </FormGroup>
    </>
  );
};

export default RenderSwitches;
