import { queryOptions } from '@tanstack/react-query';
import axios from '../axios';
import { transformEntity } from '../utils';

export const fetchExperts = async () => {
  const response = (await axios.get(`/experts?populate[0]=interest,photo,location`)).data;
  return response.data.map((d) => transformEntity(d));
};

export const fetchUnpopulatedExperts = async () => {
  const response = (await axios.get(`/experts?populate[0]=interest`)).data;

  return response.data.map((d) => {
    const { attributes } = d;
    if (attributes) {
      // Verwijder de onnodige attributen
      const { publishedAt, admin_email, user_id, createdAt, contact_tel, ...filteredAttributes } =
        attributes;

      filteredAttributes['id'] = d.id;

      return filteredAttributes;
    }
    return {};
  });
};

export const expertsQueryOptions = queryOptions({
  queryKey: ['experts'],
  queryFn: fetchExperts,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
});

export const expertsGeoMapQueryOptions = queryOptions({
  queryKey: ['experts'],
  queryFn: fetchExperts,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  select: (data) => {
    return data.map((d) => ({
      type: 'expert',
      location: d.location,
      name: d.name,
      interests: d.interest,
      id: d.id,
    }));
  },
});

export const fetchUserExperts = async (userId) => {
  const response = (await axios.get(`/experts?filters[user_id][$eq]=${userId}&populate=*`)).data;
  const experts = response.data.map((expert) => {
    return transformEntity(expert);
  });
  return experts;
};

export const updateUserExpert = async (id, newdata) => await axios.put(`/experts/${id}`, { data: newdata });

export const fetchExpert = async (id) => {
  const response = (
    await axios.get(`/experts/${id}?populate=initiative.interest,stakeholder.interest,interest,photo,location`)
  ).data;
  return transformEntity(response.data);
};

export const createUserExpert = async (data) => await axios.post(`/experts`, data);

export const claimExpert = async (id, data) =>
  await axios.put(`/experts/claim/${id}`, data).then(() => {
    window.location.reload();
  });


export const unClaimExpert = async (id) =>
  await axios.put(`/experts/unclaim/${id}`).then(() => {
    window.location.reload();
  });
