import React from 'react';
import { DashboardSidebar } from '../../components/dashboard/dashboardSidebar';
import { useConvertJSONToTableData } from '../../utils/hooks/dashboardHooks';
import { fetchUnpopulatedInitiatives } from '../../services/InitiativeService';
import DashboardTable from '../../components/dashboard/tables/DashboardTable';
import { transformDataFieldsToNames } from '../../utils/transformData';
import { downloadInitiativeTable } from '../../services/adminServices/downloadTable';
import { useLocaleStore } from '../../store';
import { getTranslation } from '../../dictionary';
import { useIsAdmin } from '../../utils/adminChecker';
import { Navigate } from 'react-router-dom';

export default function DashboardInitiatives() {
  const { tableHeaders, tableData } = useConvertJSONToTableData(fetchUnpopulatedInitiatives);
  const transformedData = transformDataFieldsToNames(tableData, [22, 23, 24, 25]);
  const language = useLocaleStore((state) => state.language);
      const { isAdmin, loading } = useIsAdmin();
  
      if (!isAdmin && !loading) {
          return <Navigate to="/" />;
      }

  return (
    <div className="d-flex">
      <DashboardSidebar />
      <div className="flex-grow-1 pt-5 pad-b">
        <DashboardTable tableHeaders={tableHeaders} tableData={transformedData} title={getTranslation({ key: 'dashboard_initiative', language })} downloadTable={downloadInitiativeTable} pageType="initiatives"/>
      </div>
    </div>
  );
}
