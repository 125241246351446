import React, { useState } from 'react';
import { getTranslation } from '../dictionary';
import { apiUrl } from '../settings';
import { useLocaleStore } from '../store';
import { getLogoIfPresent } from '../utils';
import LinkScrollTop from './LinkScrollTop';
import UpdateStakeholder from './UpdateStakeholder';
import { unclaimStakeholder } from '../services/StakeholderService';

const StakeholderCard = ({ d, showClaimButton = false }) => {
  const language = useLocaleStore((state) => state.language);
  const [isOpen, setIsOpen] = useState(false);
  const locationPath = window.location.pathname;

  const maxWords = 22;
  const translated = language === 'en' ? d?.description_en : d?.description;
  const words = translated ? translated.split(' ') : [];
  const description =
    words.length === 0
      ? 'No description.'
      : words.length <= maxWords
        ? translated
        : words.splice(0, maxWords).join(' ') + '…';

  const logo = getLogoIfPresent(d);
  const imgStyle = {
    backgroundImage: `url(${apiUrl?.slice(0, -4)}${logo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  };

  const toggleCollapsible = () => setIsOpen(!isOpen);

  const renderInterests = () => {
    let interests = d?.interest?.data || d?.interest || [];
    if (!Array.isArray(interests)) {
      interests = [];
    }

    if (interests.length === 0) {
      return getTranslation({ key: 'no_interests', language });
    }

    const maxInterests = 3;
    const displayedInterests = interests.slice(0, maxInterests).map((interest, j) => {
      if(interest.attributes) {
        return language === 'en' ? interest.attributes.name_en : interest.attributes.name_nl;
      }
      return language === 'en' ? interest.name_en : interest.name_nl;
    });

    if (interests.length > maxInterests) {
      displayedInterests.push(`${interests.length - maxInterests} ${getTranslation({ key: 'more', language })}`);
    }

    return displayedInterests.join(', ');
  };

  const handleUnClaim = (event) => {
    event.stopPropagation();
    unclaimStakeholder(d.id);
  };

  const renderClaimButton = () => (
    <>
      <div>
        <div className="font-sans-s color-black-50 mt-4">{getTranslation({ key: 'claim', language })}</div>
        <div className="font-serif-m mt-1">
          <div>
            <p
              className="color-c2 font-serif-m font-strong-m my-1 cursor-pointer claim-button"
              onClick={(event) => handleUnClaim(event)}
            >
              Unclaim
            </p>
          </div>
        </div>
      </div>
    </>
  )

  const renderCardContent = () => (
    <>
      <header>
        <div className="left">
          <div className="font-sans-s font-700 color-c2">
            {getTranslation({ key: 'stakeholder', language }).toUpperCase()}
          </div>
          <div className="card-title">{d?.name}</div>
          <div className="font-sans-s color-blackish-80">{renderInterests()}</div>
        </div>
        {logo && (
          <div className="right">
            <div className="round-img thumb" style={imgStyle}></div>
          </div>
        )}
      </header>
      <div className="card-body">
        <div className="font-serif-m font-400">{description}</div>
        {showClaimButton && renderClaimButton()}
      </div>
    </>
  );

  const renderUpdateButton = () => (
    <>
      <button className="font-serif-m font-400" onClick={toggleCollapsible}>
        {isOpen ? getTranslation({ key: 'close', language }) : getTranslation({ key: 'update', language })} &#9660;
      </button>
      {isOpen && (
        <div className="collapsible-content">
          <UpdateStakeholder props={d} setIsOpen={setIsOpen} />
        </div>
      )}
    </>
  );

  const isAuthenticated = sessionStorage.getItem('stytch-user');
  const isOwner = isAuthenticated && JSON.parse(isAuthenticated)?.user_id === d?.user_id;
  const showUpdateButton = isOwner && locationPath === '/data';

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} className="col-md-6 col-xl-4 card-stakeholder-col">
      <LinkScrollTop className="card-stakeholder" style={{ flex: 1 }} to={`/stakeholders/${d?.id}`}>
        {renderCardContent()}
      </LinkScrollTop>
      {showUpdateButton && renderUpdateButton()}
    </div>
  );
};

export default StakeholderCard;