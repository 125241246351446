import React from 'react';
import { DashboardSidebar } from '../../components/dashboard/dashboardSidebar';
import { downloadIndicatorTable } from '../../services/adminServices/downloadTable';
import { useConvertJSONToTableData } from '../../utils/hooks/dashboardHooks';
import DashboardTable from '../../components/dashboard/tables/DashboardTable';
import { fetchUnpopulatedIndicators } from '../../services/IndicatorService';
import { useLocaleStore } from '../../store';
import { getTranslation } from '../../dictionary';
import { useIsAdmin } from '../../utils/adminChecker';
import { Navigate } from 'react-router-dom';

export default function DashboardIndicators() {
  const { tableHeaders, tableData } = useConvertJSONToTableData(fetchUnpopulatedIndicators);
  const language = useLocaleStore((state) => state.language);
  const { isAdmin, loading } = useIsAdmin();

  if (!isAdmin && !loading) {
    return <Navigate to="/" />;
  }

  return (
    <div className="d-flex">
      <DashboardSidebar />
      <div className="flex-grow-1 pt-5 pad-b">
        <DashboardTable tableHeaders={tableHeaders} tableData={tableData} title={getTranslation({ key: 'dashboard_indicator', language })} downloadTable={downloadIndicatorTable} pageType="indicators" />
      </div>
    </div>
  );
}
