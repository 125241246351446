import { queryOptions } from '@tanstack/react-query';
import axios from '../axios';
import { transformEntity } from '../utils';

export const fetchInitiatives = async () => {
  const response = (await axios.get(`/initiatives?populate=*`)).data;
  const initiatives = response.data.map((initiative) => {
    return transformEntity(initiative);
  });
  return initiatives;
};

export const fetchUnpopulatedInitiatives = async () => {
  const response = (await axios.get(`/initiatives?populate[0]=interest`)).data;
  return response.data.map((d) => {
    const { attributes } = d;
    if (attributes) {
      // Verwijder de onnodige attributen
      const { capacity, registration, createdAt, publishedAt, ...filteredAttributes } = attributes;

      filteredAttributes['id'] = d.id;

      return filteredAttributes;
    }
    return {};
  });
};

export const initiativesQueryOptions = queryOptions({
  queryKey: ['initiatives'],
  queryFn: fetchInitiatives,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
});

export const fetchUserInitiatives = async (userId) => {
  const response = (await axios.get(`/initiatives?filters[user_id][$eq]=${userId}&populate=*`)).data;
  const initiatives = response.data.map((initiative) => {
    return transformEntity(initiative);
  });
  return initiatives;
};

export const createUserInitiative = async (data) => await axios.post(`/initiatives`, data);

export const updateUserInitiative = async (id, newdata) => await axios.put(`/initiatives/${id}`, { data: newdata });

export const fetchInitiative = async (id) => {
  const response = (await axios.get(`/initiatives/${id}?populate=*`)).data;
  return transformEntity(response.data);
};

export const claimInitiative = async (id, data) =>
  await axios.put(`/initiatives/claim/${id}`, data).then(() => {
    window.location.reload();
  });

export const unclaimInitiative = async (id) =>
  await axios.put(`/initiatives/unclaim/${id}`).then(() => {
    window.location.reload();
  });
