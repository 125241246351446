import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import InitiativeCard from '../components/InitiativeCard';
import Loading from '../components/Loading';
import StakeholderCard from '../components/StakeholderCard';
import { getTranslation } from '../dictionary';
import { claimExpert, fetchExpert, unClaimExpert } from '../services/ExpertService.js';
import { apiUrl } from '../settings';
import { useLocaleStore } from '../store';
import { addhttp, cleanUrlText, getPhotoIfPresent } from '../utils';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';

const ContactInfo = ({ icon: Icon, children }) => (
  <div className="py-1">
    <Icon className="color-black-50" /> {children}
  </div>
);

const Section = ({ titleKey, language, children }) => (
  <div className="break-inside-avoid mr-xl-5 pt-1">
    <div className="font-sans-s color-black-50 mt-4">
      {getTranslation({ key: titleKey, language })}
    </div>
    <div className="font-serif-m mt-1">{children}</div>
  </div>
);

function Expert() {
  const language = useLocaleStore((state) => state.language);
  const { id } = useLoaderData();
  const { isLoading, error, data: expert } = useQuery({
    queryKey: [`expert${id}`],
    queryFn: () => fetchExpert(id),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const handleClaim = (event, isAlreadyClaimed) => {
    event.stopPropagation();
    const newData = {
      data: {
        user_id: JSON.parse(sessionStorage.getItem('stytch-user'))?.user_id,
      },
    };
    if (isAlreadyClaimed) {
      if (window.confirm(getTranslation({ key: 'confirmReclaim', language }))) {
        claimExpert(id, newData);
      }
    } else {
      claimExpert(id, newData);
    };

  };

  const handleUnClaim = (event) => {
    event.stopPropagation();
    unClaimExpert(id);
  };

  if (isLoading) return <Loading />;
  if (error) return <>{error.message}</>;

  const photo = getPhotoIfPresent(expert);
  const imgStyle = {
    backgroundImage: `url(${apiUrl?.slice(0, -4) + photo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  };

  const interests = Array.isArray(expert?.interest) ? expert.interest : expert?.interest?.data || [];

  return (
    <div className="stakeholder-page expert">
      {expert && (
        <section className="py-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h2 className="mb-1 mt-space">{expert.name}</h2>
                <h5 className="color-c1 font-strong-m mt-2 pb-2">
                  {getTranslation({ key: 'expert', language })}
                  {expert.website && ' — '}
                  {expert.website && (
                    <a href={addhttp(expert.website)} target="_blank" className="external color-c1" rel="noreferrer">
                      {cleanUrlText(expert.website)}
                    </a>
                  )}
                </h5>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="bg-white py-4 pb-5">
        {expert && (
          <div className="container-fluid">
            <div className="row content">
              {photo && (
                <div className="col-12 my-4 d-xl-none">
                  <div className="round-img" style={imgStyle}></div>
                </div>
              )}
              <div className="col-md-8 col-lg-12 col-xl-10 two-cols-lg">
                <div className="break-inside-avoid">
                  <div className="font-sans-s color-black-50 mt-4">
                    {getTranslation({ key: 'description', language })}
                  </div>
                  <div className="font-serif-m mt-1 mr-sm-5">
                    {language === 'en' ? expert.description_en : expert.description}
                  </div>
                </div>

                {interests.length > 0 && (

                  <Section titleKey="interests" language={language}>
                    {interests.map((interest, j) => {
                      const interestName = language === 'en' ? interest.name_en : interest.name_nl;
                      return (
                        <span key={j}>
                          {j > 0 ? ', ' : ''}
                          {interestName}
                        </span>
                      );
                    })}
                  </Section>
                )}

                {(expert.contact_email || expert.contact_tel) && (
                  <Section titleKey="contacts" language={language}>
                    {expert.contact_email && (
                      <ContactInfo icon={EmailIcon}>
                        <a href={`mailto:${expert.contact_email}`} className="hover_email">
                          {expert.contact_email}
                        </a>
                      </ContactInfo>
                    )}
                    {expert.contact_tel && (
                      <ContactInfo icon={PhoneIcon}>{expert.contact_tel}</ContactInfo>
                    )}
                  </Section>
                )}

                {expert.website && (
                  <Section titleKey="website" language={language}>
                    <ContactInfo icon={LanguageIcon}>
                      <a href={addhttp(expert.website)} target="_blank" className="external hover_email" rel="noreferrer">
                        {cleanUrlText(expert.website)}
                      </a>
                    </ContactInfo>
                  </Section>
                )}

                {expert.address && (
                  <Section titleKey="location" language={language}>
                    <ContactInfo icon={LocationOnIcon}>{expert.address}</ContactInfo>
                  </Section>
                )}
              </div>

              {photo && (
                <div className="col-md-2 my-4 d-none d-xl-flex justify-content-end">
                  <div className="round-img" style={imgStyle}></div>
                </div>
              )}
            </div>
            {sessionStorage.getItem('stytch-user') ? (
              (() => {
                const stytchUser = JSON.parse(sessionStorage.getItem('stytch-user'));
                const isClaimable = expert?.user_id === null || expert?.user_id !== stytchUser?.user_id;
                const isAlreadyClaimed = expert?.user_id !== null && expert?.user_id !== stytchUser?.user_id;
                const isUnclaimable = expert?.user_id === stytchUser?.user_id;

                if (isClaimable) {
                  return (
                    <div>
                      <div className="font-sans-s color-black-50 mt-4">{getTranslation({ key: 'claim', language })}</div>
                      <div className="font-serif-m mt-1">
                        <div>
                          <p
                            className="color-c1 font-serif-m font-strong-m my-1 cursor-pointer claim-button"
                            onClick={(e) => handleClaim(e, isAlreadyClaimed)}
                          >
                            Claim
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                } else if (isUnclaimable) {
                  return (
                    <div>
                      <div className="font-sans-s color-black-50 mt-4">{getTranslation({ key: 'unclaim', language })}</div>
                      <div className="font-serif-m mt-1">
                        <div>
                          <p
                            className="color-c1 font-serif-m font-strong-m my-1 cursor-pointer claim-button"
                            onClick={handleUnClaim}
                          >
                            Unclaim
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })()
            ) : null}


          </div>
        )}
      </section>

      {(expert?.initiative?.length > 0 || expert?.stakeholder?.length > 0) && (
        <section className="stakeholders-cards py-4 pb-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h2 className="mt-space-2 mb-space">{getTranslation({ key: 'related', language })}</h2>
              </div>
            </div>
            <div className="row">
              {expert?.initiative?.map((d, i) => (
                <InitiativeCard d={d} key={i} />
              ))}
              {expert?.stakeholder?.map((d, i) => (
                <StakeholderCard d={d} key={i} />
              ))}
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Expert;